import { UpdateState } from './PartialHost';

function setupBotForm()
{
    const submitBtn = document.querySelector("#bot-form a.btn-submit") as HTMLAnchorElement | null;
    if (!submitBtn)
    {
        return;
    }

    const form = document.querySelector("#bot-form form") as HTMLFormElement | null;
    if (!form)
    {
        return;
    }

    const targetUrl = submitBtn.href;
    if (!targetUrl)
    {
        return;
    }

    submitBtn.href = "#";
    submitBtn.classList.add("disabled");

    submitBtn.addEventListener("click", function ()
    {
        const state = new UpdateState({
            url: form.action,
            content: new FormData(form),
            callback: function () { }
        });

        state.update();
    });

    const checks = document.querySelectorAll("#bot-form input[type=checkbox]");
    for (let i = 0; i < checks.length; i++)
    {
        checks[i].addEventListener("change", function ()
        {
            if (document.querySelectorAll("#bot-form input[type=checkbox]:not(:checked)").length === 0)
            {
                submitBtn.href = targetUrl;
                submitBtn.classList.remove("disabled");
            }
            else
            {
                submitBtn.href = "#";
                submitBtn.classList.add("disabled");
            }
        });
    }
};

setupBotForm();